import consumer from "./consumer";

if (i_type === 'operator') {
  App.channels.operators = consumer.subscriptions.create({
    channel: "OperatorsChannel"
  }, {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log('Connected to OperatorsChannel');

      // Запросим количество сообщений
      App.channels.operators.send({action: "waiter_ring_messages"});
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      console.log("OperatorsChannel::Receiving:", data);

      if (typeof data.action !== 'undefined') {
        if (data.action === 'waiter_ring') {
          // колокольчик официанту
          if (typeof App.operator.$refs.waiter_ring_messages !== 'undefined') {
            App.operator.$refs.waiter_ring_messages.setWaiterRings(data.waiter_rings);
          }
          if (typeof App.operator.$refs.waiter_ring_unread !== 'undefined') {
            App.operator.$refs.waiter_ring_unread.setWaiterRingsUnread(data.waiter_rings_unread);

            if (data.waiter_rings_unread) {
              if (typeof localStorage.last_waiter_rings == 'undefined') {
                localStorage.last_waiter_rings = 0;
              }

              for (let i = 0, len = data.waiter_rings.length, id; i < len; i++) {
                if (data.waiter_rings[i].is_tapped) {
                  continue;
                }
                id = data.waiter_rings[i].id;
                if (localStorage.last_waiter_rings < id) {
                  localStorage.last_waiter_rings = id;
                  $.playSound("/sounds/waiter_ring.mp3", false).trigger('play');
                }
              }
            }
          }
        } else if (data.action === 'new_order') {
          // новый заказ в списке
          let $item = $(data.item), code = data.code;

          $item.addClass('boxes__item--new_order').hide();
          $('.boxes__item:first').after($item);
          $item.slideDown('slow');

          if (data.force_operator_notify) {
            localStorage.removeItem('last_order_code');
          }

          if (localStorage.getItem('last_order_code') !== code) {
            localStorage.setItem('last_order_code', code);
            $.playSound("/sounds/new_order.mp3", false).trigger('play');
          }
        } else if (data.action === 'update_order') {
          // обновление заказа
          let $item = $(data.item), $full = $(data.full), code = data.code,
            $dstItem = $('.boxes__item[data-order-code="' + code + '"]'),
            $dstFull = $('.info[data-order-code="' + code + '"]');

          // в списке
          if (data.is_waiter && $item.hasClass('boxes__item--is_closed')) {
            $item.hide();
            if ($dstFull.length > 0) {
              location.href = '/o/'
            }
          }
          $dstItem.fadeOut().replaceWith($item);

          // в детальном просмотре
          $dstFull.fadeOut().replaceWith($full);
        }
      }
    }
  });
}
