import consumer from "./consumer";

if (i_type === 'menu') {
  App.services.subscribeOrderStatus = function (order_code) {
    if (typeof App.channels.ordersStatus[order_code] !== 'undefined') {
      return App.channels.ordersStatus[order_code];
    }

    const subscription = consumer.subscriptions.create({
      channel: "OrdersStatusChannel",
      order_code: order_code
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
        // console.log("Connected to OrdersStatusChannel:" + order_code);

        // Connected —> Store order
        App.channels.ordersStatus[order_code] = subscription;
        if (!App.cart.$refs.dishes.$store.getters.getMyOrders.find(o => o == order_code)) {
          App.cart.$refs.dishes.$store.commit('addMyOrder', order_code);
          App.cart.$refs.dishes.$store.commit('clearCart');
          // $('.modal--thx').addClass('modal--show');
        }
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        // console.log("Receiving 1:", data.order_code);

        if (typeof data.order_code == 'undefined') {
          return;
        }

        App.cart.$refs.dishes.$refs.my_order.addToMyOrders(data);
      }
    });

    return subscription;
  };
}
