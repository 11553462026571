import consumer from "./consumer";

if (i_type === 'menu') {
  App.channels.orders = consumer.subscriptions.create({
    channel: "OrdersChannel"
  }, {
    connected() {
      // Called when the subscription is ready for use on the server
      // console.log('Connected to OrdersChannel');
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      // console.log("Receiving:", data);

      if (typeof data.order === 'object' && typeof data.order.code !== 'undefined') {
        // подпишемся
        App.services.subscribeOrderStatus(data.order.code);

        // вызываем оплату Я.Касса
        if (menu.restaurant.without_payment) {
          // @todo: окно с успешным заказом
          location.href = location.href.replace(/\?.+/, '') + '?o=' + data.order.code;
        } else {
          let y = data.order.ya_order_res;
          if (y.status === 'pending' && typeof y.confirmation === 'object') {
            jewelryModals.hide();
            location.href = y.confirmation.confirmation_url;
          }
        }
      }

      if (typeof data.waiter_ring !== 'undefined') {
        // колокольчик
      }
    }
  });
}
