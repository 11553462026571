$(document).ready(function () {
  // console.log('//@ Тут обработчики устанавливаются один раз…');

  $(document)
    .on('click', '.card__image, .card__body .card__title', function () {
      let $t = $(this).closest('.card').find('.card__image'), $img = $t.find('img'), src = $img.attr('src'),
        dstsrc = src,
        dish_id = $t.data('dish-id');

      // манипуляции с картинками
      if ($img.data('srcbig')) {
        dstsrc = $img.data('srcbig');
      } else if ($img.data('srcsmall')) {
        dstsrc = $img.data('srcsmall');
      }
      // console.log(dstsrc);

      let $dstImg = $('.modal__image img', '.modal--food');
      $('<img />').on('load', function () { $dstImg.attr('src', dstsrc);}).attr('src', dstsrc);
      $dstImg.attr('src', src);

      // заголовок и прочие тексты
      $('.modal__title', '.modal--food').text($img.data('name'));
      let $modal__text = $('.modal__text').empty();
      if (menu.dishes[dish_id].desc) {
        $modal__text.html(menu.dishes[dish_id].desc);
      }

      let $modal__nutritional = $('.modal__nutritional').empty(), mn_inner = [];
      if (menu.dishes[dish_id].weight) {
        mn_inner.push(menu.dishes[dish_id].weight + ' гр');
      }
      if (menu.dishes[dish_id].energy_value) {
        mn_inner.push(menu.dishes[dish_id].energy_value + ' ккал');
      }
      if (mn_inner.length > 0) {
        $modal__nutritional.html('<div>' + mn_inner.join('</div><div>') + '</div>');
      }

      $('.modal__price').empty();
      if (menu.dishes[dish_id].price) {
        $('.modal__price').html('₽ ' + menu.dishes[dish_id].price);
      }

      $('.modal--food').addClass('modal--show');
      return false;
    })
    // .on('click', '.js-modal-thx', function () {
    //   $('.modal--thx').addClass('modal--show');
    //   $(this).text('Оплатить');
    //   return false;
    // })
    .on('click', '.modal__close', function () {
      $('.modal').removeClass('modal--show');
      return false;
    })
    .on('click', '.line', function () {
      if ($('.line__circle', $(this)).is(':checked')) {
        $(this).removeClass('line--disabled');
      } else {
        $(this).addClass('line--disabled');
      }
    })
    .on('DOMSubtreeModified', '.menu__item-number', function () {
      decimalMenuItemCircle();
    })
    .on('showModalAlert', function () {
      // показываем бродкаст-модалку 1 раз в 15 минут, если она задана в админке
      let k = 'broadcast_message_at', prev = localStorage.getItem(k), prevT, curT = (new Date).getTime();
      if (prev === null) {
        prev = '' + (new Date).getTime();
      }
      prevT = (new Date(parseInt(prev, 10)));

      if (prevT <= curT) {
        jewelryModals.show('modal--alert');
        localStorage.setItem(k, curT + 15 * 60 * 1000);
      }
    });

  decimalMenuItemCircle();

  function clearMenuItemCircle() {
    $('.menu__item-circle').removeClass('ones tens hundreds');
  }

  function decimalMenuItemCircle() {
    if ($('.menu__item-number').html() >= 10 && $('.menu__item-number').html() < 100) {
      clearMenuItemCircle();
      $('.menu__item-circle').addClass('tens');
    } else if ($('.menu__item-number').html() >= 100) {
      clearMenuItemCircle();
      $('.menu__item-circle').addClass('hundreds');
    } else {
      clearMenuItemCircle();
      $('.menu__item-circle').addClass('ones');
    }
  }

  // рейтинг
  (function () {
    $('.rate-yo')
      .rateYo({
        starSvg: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.326 18.266l-4.326-2.314-4.326 2.313.863-4.829-3.537-3.399 4.86-.671 2.14-4.415 2.14 4.415 4.86.671-3.537 3.4.863 4.829z"/></svg>',
        normalFill: '#efefef',
        multiColor: {
          'startColor': '#e00202',// red
          'endColor': '#b2e002' // green
        },
        spacing: '10px',
        numStars: 5,
        maxValue: 5,
        precision: 0,
        halfStar: true,
        onSet: function (rate, rateYoInstance) {
          $('.js--rate__send').data('rate', rate);
        }
      });

    $('.js--rate__send').on('click', function (e) {
      e.preventDefault();
      let $t = $(this), rate = $t.data('rate');

      if (typeof Cookies.get('rate') === 'undefined') {
        App.channels.orders.send({
          action: "rate",
          table: App.cart.$store.getters.getTable,
          rate: rate,
          comment: $('.js--rate__comment').val()
        });

        Cookies.set('rate', rate, {path: '/'});
      }

      $('.modal--rateyo').removeClass('modal--show');
    });
  })();
});
